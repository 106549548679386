import React from 'react';
import RegisterForm from '../components/forms/RegisterForm';
import CodeForm from '../components/forms/CodeForm';
import { Drawer } from 'antd';

const Register = (props) => {
  const [correctCode, setCorrectCode] = React.useState(false);

  const handleCorrectCode = () => {
    setCorrectCode(true);
  };

  return (
    <Drawer
      mask={false}
      title={correctCode ? 'Register' : 'Enter code'}
      placement="bottom"
      contentWrapperStyle={{
        borderRadius: 10,
        overflow: 'hidden',
      }}
      height={correctCode ? '70vh' : '30vh'}
      visible={props.visible}
      onClose={props.close}
    >
      {correctCode ? (
        <RegisterForm handleVisible={props.handleVisible} />
      ) : (
        <CodeForm onCorrectCode={handleCorrectCode} />
      )}
    </Drawer>
  );
};

export default Register;
