import React from 'react';
import { Button, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../context/FirebaseContext';

const Settings = () => {
  const [password, setPassword] = React.useState('');
  const [passwordConfirmed, setPasswordConfirmed] = React.useState(false);
  const { updatePassword } = React.useContext(FirebaseContext);

  const handleUpdate = async () => {
    if (passwordConfirmed) {
      await updatePassword(password);
      setPassword('');
      setPasswordConfirmed(false);
    }
  };

  const checkPassword = (value) => {
    if (value === password) {
      setPasswordConfirmed(true);
    }
  };

  return (
    <div className="home-main">
      <h3>Update password</h3>
      <Input.Password
        id="passwordInput"
        className="inputField bright marginBottom"
        placeholder="New password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <Input.Password
        id="confirmPassword"
        className="inputField bright marginBottom"
        placeholder="Confirm password"
        onChange={(e) => checkPassword(e.target.value)}
      />
      <Button className="plus-btn" shape="circle" icon={<SendOutlined />} onClick={handleUpdate} />
    </div>
  );
};

export default Settings;
