import React from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
  const { isLoggedIn } = React.useContext(FirebaseContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? children : <Redirect to={{ pathname: '/', state: { from: location } }} />
      }
    />
  );
};

export default PrivateRoute;
