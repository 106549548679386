import React from 'react';
import { Select } from 'antd';
import { FirebaseContext } from '../context/FirebaseContext';
import FundCardList from '../components/lists/FundCardList';

const Search = () => {
  const [contactData, setContactData] = React.useState(null);
  const [searchData, setSearchData] = React.useState(null);
  const { getContacts } = React.useContext(FirebaseContext);

  React.useEffect(() => {
    const fetchContactData = async () => {
      const contacts = await getContacts();

      if (contacts !== false) {
        const cityArray = [
          {
            value: 'All',
            label: 'All states',
          },
        ];
        const dupeArray = [];

        contacts.forEach((contact) => {
          if (contact.fund != null && !dupeArray.includes(contact.state)) {
            cityArray.push({
              value: contact.state,
              label: contact.state,
            });

            dupeArray.push(contact.state);
          }
        });

        setSearchData(cityArray);
        setContactData(contacts);
      }
    };

    fetchContactData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = async (value) => {
    console.log(value);
    const contacts = await getContacts();

    if (contacts == null) {
      return;
    }

    if (value === 'All') {
      setContactData(contacts);
      return;
    }

    // eslint-disable-next-line
    const selectedItem = contacts.filter((item) => item.state == value);
    console.log(selectedItem);

    setContactData([...selectedItem]);
  };

  const filter = (inputValue, path) => {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  };

  return (
    <div className="home-main">
      {searchData != null && (
        <Select
          className="cascadeField"
          size="large"
          options={searchData}
          onChange={onChange}
          placeholder="Please select state"
          showSearch={{ filter }}
          allowClear={false}
        />
      )}
      {contactData != null && <FundCardList listData={contactData} />}
    </div>
  );
};

export default Search;
