import React from 'react';
import { List } from 'antd';
import ContactCardListItem from './ContactCardListItem';

const FundCardList = (props) => {
  const { listData } = props;

  return (
    <List
      className="card-list"
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 5,
        simple: true,
      }}
      dataSource={listData}
      renderItem={(item) => <ContactCardListItem item={item} />}
    />
  );
};

export default FundCardList;
