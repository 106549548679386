import React from 'react';
import logo from '../assets/NNN-logo-horizontal.svg';
import BottomNav from '../components/menu/BottomNav';
import { Switch, Route } from 'react-router-dom';
import Search from './Search';
import Settings from './Settings';
import Boards from './Boards';
import SingleBoard from './SingleBoard';

const Home = () => {
  return (
    <div className="App light">
      <header className="App-titlebar">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Switch>
        <Route exact path="/home">
          <Search />
        </Route>
        <Route path="/home/boards">
          <Boards />
        </Route>
        <Route path="/home/settings">
          <Settings />
        </Route>
        <Route path="/home/singleboard/:id">
          <SingleBoard />
        </Route>
      </Switch>
      <BottomNav />
    </div>
  );
};

export default Home;
