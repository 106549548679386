/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import logo from '../assets/NNN-logo.svg';
import mark from '../assets/white-sq.svg';
import { Button, Form, Input } from 'antd';
import Register from './Register';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Transition } from 'react-transition-group';
import Lottie from 'react-lottie';
import arrowRightCircle from '../assets/lottie/arrowRightCircle.json';
import { FirebaseContext } from '../context/FirebaseContext';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';

const SignIn = () => {
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [focusUsername, setFocusUsername] = React.useState(false);
  const [focusPassword, setFocusPassword] = React.useState(false);
  const [registerVisible, setRegisterVisible] = React.useState(false);
  const { isLoggedIn, login } = React.useContext(FirebaseContext);
  let history = useHistory();

  React.useEffect(() => {
    if (isLoggedIn) {
      history.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const userRef = React.createRef();
  const passRef = React.createRef();
  const btnRef = React.createRef();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: arrowRightCircle,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultUsernameStyle = {
    width: 0,
    opacity: 0,
    transform: 'translate3d(0, 70px, 0)',
    transition: 'all 200ms',
    position: 'absolute',
    overflow: 'hidden',
  };

  const defaultPasswordStyle = {
    transition: 'all 200ms',
    width: 0,
    opacity: 0,
    transform: 'translate3d(0, 70px, 0)',
    overflow: 'hidden',
    position: 'absolute',
  };

  const defaultButtonStyle = {
    color: '#307151',
    background: 'white',
    border: 'none',
    marginTop: '15em',
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
    transition: 'all 180ms',
  };

  const passwordStyles = {
    entering: {
      width: '80%',
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    entered: {
      width: '80%',
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    exiting: {
      width: 0,
      opacity: 0,
      transform: 'translate3d(0, 0, 0)',
    },
    exited: {
      width: 0,
      opacity: 0,
      transform: 'translate3d(0, 0, 0)',
    },
  };

  const usernameStyles = {
    entering: {
      width: '80%',
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    entered: {
      width: '80%',
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
      transition: 'all 200ms',
    },
    exiting: {
      width: 0,
      opacity: 0,
      transform: 'translate3d(0, 0, 0)',
    },
    exited: {
      width: 0,
      opacity: 0,
      transform: 'translate3d(0, 70px, 0)',
    },
  };

  const buttonStyles = {
    exiting: {
      opacity: '0',
      width: '278px',
      borderRadius: '12px',
      transform: 'translate3d(0, -60px, 0)',
    },
    exited: {
      opacity: '0',
      width: '278px',
      borderRadius: '12px',
      transform: 'translate3d(0, 0, 0)',
    },
    entering: {
      opacity: 1,
      borderRadius: '40px',
      transform: 'translate3d(0, 0, 0)',
    },
    entered: {
      opacity: 1,
      borderRadius: '40px',
      transform: 'translate3d(0, 0, 0)',
    },
  };

  const handleSubmit = async () => {
    if (!focusUsername && !focusPassword) {
      if (username == null) {
        setFocusUsername(true);
        return;
      }

      if (password == null) {
        setFocusPassword(true);
        return;
      }
    }

    if (focusUsername && username != null) {
      setFocusUsername(false);
      setFocusPassword(true);
      return;
    }

    if (focusPassword && password != null) {
      setFocusPassword(false);

      if (username != null) {
        setFocusUsername(false);
        await login(username, password);
        setUsername(null);
        setPassword(null);
      }
    }
  };

  return (
    <div className="App dark">
      <header className="App-header">
        <img src={logo} className="App-logo white-logo" alt="logo" />
      </header>
      <div className="watermark">
        <img src={mark} className="mark-img" alt="watermark" />
      </div>
      <Form
        name="sign-in"
        className="column"
        style={{
          padding: 30,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
        size="large"
      >
        <Transition nodeRef={userRef} in={focusUsername} timeout={300}>
          {(state) => (
            <Form.Item
              style={{
                ...defaultUsernameStyle,
                ...usernameStyles[state],
              }}
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your Username!',
                },
              ]}
            >
              <Input
                id="username"
                className="inputField"
                prefix={<UserOutlined />}
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                suffix={
                  username != null &&
                  username.length > 4 && (
                    <Button className="hidden-btn" ghost onClick={handleSubmit}>
                      <Lottie options={defaultOptions} height={32} width={32} />
                    </Button>
                  )
                }
              />
            </Form.Item>
          )}
        </Transition>
        <Transition nodeRef={passRef} in={focusPassword} timeout={300}>
          {(state) => (
            <Form.Item
              style={{ ...defaultPasswordStyle, ...passwordStyles[state] }}
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input.Password
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                className="inputField"
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
          )}
        </Transition>
        <Transition
          nodeRef={btnRef}
          in={
            (!focusUsername && !focusPassword) ||
            (!focusUsername && username != null && username.length > 4 && password != null)
          }
          timeout={200}
        >
          {(state) => (
            <Button
              style={{
                ...defaultButtonStyle,
                ...buttonStyles[state],
              }}
              type="primary"
              size="large"
              shape="round"
              icon={<UserOutlined />}
              onClick={handleSubmit}
            >
              Sign-In
            </Button>
          )}
        </Transition>
        <a
          style={{ marginTop: 18, fontSize: 14, color: 'white', fontWeight: '600' }}
          onClick={() => setRegisterVisible(true)}
        >
          Enter Invitation Code
        </a>
      </Form>
      <Register
        visible={registerVisible}
        handleVisible={(visible) => setRegisterVisible(visible)}
        close={() => setRegisterVisible(false)}
      />
    </div>
  );
};

export default SignIn;
