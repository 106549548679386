import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input } from 'antd';
import {
  UserOutlined,
  CalendarOutlined,
  PlusOutlined,
  CloseOutlined,
  SendOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { FirebaseContext } from '../context/FirebaseContext';
import CommentItem from '../components/lists/CommentItem';

const SingleBoard = () => {
  const { id } = useParams();
  const [author, setAuthor] = React.useState('');
  const [board, setBoard] = React.useState(null);
  const [comments, setComments] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [reply, setReply] = React.useState('');
  const [editMessage, setEditMessage] = React.useState('');
  const [update, setUpdate] = React.useState(0);
  const {
    getUserByID,
    getBoardByID,
    getPostComments,
    addComment,
    isAuthCurrentUser,
    editBoard,
  } = React.useContext(FirebaseContext);

  const getBoard = async () => {
    const commentData = await getPostComments(id);
    const boardData = await getBoardByID(id);
    const username = await getUserByID(boardData.author);

    setComments(commentData);
    setAuthor(username);
    setBoard(boardData);
  };

  React.useEffect(() => {
    getBoard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  if (board == null) {
    return null;
  }

  const handleSubmit = () => {
    const commentMessage = reply;
    addComment(id, commentMessage);
    setReply('');
    setVisible(false);
    renderUpdate();
  };

  const renderUpdate = () => {
    let currentUpdate = update;
    setUpdate(++currentUpdate);
  };

  const commentItems =
    comments != null
      ? comments.map((item) => (
          <CommentItem
            key={item.id}
            boardID={id}
            renderUpdate={() => renderUpdate()}
            comment={item}
          />
        ))
      : null;

  const handleEditMessage = () => {
    editBoard(id, editMessage);
    setEditing(false);
    renderUpdate();
  };

  const handleStartEditing = () => {
    setVisible(false);
    setEditMessage(board.message);
    setEditing(!editing);
  };

  return (
    <div className="home-main white-bg">
      <Button
        className="plus-btn"
        shape="circle"
        onClick={() => {
          setVisible(!visible);
          setEditing(false);
        }}
        icon={visible ? <CloseOutlined /> : <PlusOutlined />}
      />
      {visible && (
        <Input
          id="commentInput"
          className="inputField bright marginBottom"
          placeholder="Add comment"
          onChange={(e) => setReply(e.target.value)}
          suffix={
            <Button
              disabled={reply.length < 4}
              shape="circle"
              icon={<SendOutlined />}
              ghost
              onClick={handleSubmit}
            />
          }
        />
      )}
      <h3>
        {board.title}{' '}
        {isAuthCurrentUser(board.author) && (
          <Button className="edit-icon" onClick={handleStartEditing} icon={<EditOutlined />} />
        )}
      </h3>
      <p className="meta-text">
        <UserOutlined className="green-icon pad-right" />
        {author}
        <br /> <CalendarOutlined className="green-icon pad-right" />
        {new Date(board.created.toDate()).toDateString()}
      </p>
      {!editing && <p>{board.message}</p>}
      {editing && (
        <>
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 8 }}
            id="commentInput"
            className="inputField bright marginBottom"
            value={editMessage}
            onChange={(e) => setEditMessage(e.target.value)}
          />
          <Button
            className="plus-btn"
            shape="circle"
            icon={<SendOutlined />}
            onClick={handleEditMessage}
          />
        </>
      )}
      {comments != null && <h4>Comments</h4>}
      {commentItems}
    </div>
  );
};

export default SingleBoard;
