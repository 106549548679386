import 'babel-polyfill';
import React from 'react';
import SignIn from './views/SignIn';
import Home from './views/Home';
import logo from './assets/NNN-logo.svg';
import './App.css';
import { FirebaseProvider } from './context/FirebaseContext';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/menu/PrivateRoute';
import { Button } from 'antd';
import { isInstalled } from './services/InstallService';
import usePWA from 'react-pwa-install-prompt';
import PwaInstallPopupIOS from 'react-pwa-install-ios';
import { isIOS } from 'react-device-detect';

function App() {
  const [installed, setInstalled] = React.useState(false);
  const { promptInstall } = usePWA();

  React.useEffect(() => {
    const installedCheck = isInstalled();
    setInstalled(installedCheck);
    // eslint-disable-next-line
  }, []);

  const handleInstall = async () => {
    const didInstall = await promptInstall();

    if (didInstall) {
      setInstalled(true);
    }
  };

  if (!installed) {
    return (
      <div
        style={{
          background: 'white',
          padding: '2em',
          display: 'flex',
          flex: 1,
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={logo} style={{ marginBottom: '3em' }} className="App-logo" alt="logo" />
        {isIOS && (
          <div style={{ textAlign: 'left' }}>
            <p style={{ textAlign: 'center', textDecoration: 'underline' }}>
              <strong>Install</strong>
            </p>
            <ol type="1">
              <li>Make sure you're visiting this link in Safari and click the share button.</li>
              <li>Click on share.</li>
              <li>Select - 'Add to homescreen'.</li>
            </ol>
          </div>
        )}
        {!isIOS && <Button onClick={handleInstall}>Install</Button>}
        {isIOS && <PwaInstallPopupIOS delay={3} lang="en"></PwaInstallPopupIOS>}
      </div>
    );
  }

  return (
    <FirebaseProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <SignIn />
          </Route>
          <PrivateRoute path="/home">
            <Home />
          </PrivateRoute>
        </Switch>
      </Router>
    </FirebaseProvider>
  );
}

export default App;
