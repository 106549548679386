import React from 'react';
import { List, Button } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

const ContactCardListItem = ({ item }) => {
  return (
    <List.Item
      key={item.fund + '-' + item.name}
      extra={
        <Button
          className="plus-btn"
          shape="circle"
          href={`tel:${item.phone}`}
          icon={<PhoneOutlined />}
        />
      }
    >
      <List.Item.Meta title={item.fund} />
      <div>
        {item.name}
        <br />
        {item.state}
        <br />
      </div>
    </List.Item>
  );
};

export default ContactCardListItem;
