import React from 'react';
import { Link } from 'react-router-dom';
import { Button, List, Popconfirm } from 'antd';
import { UserOutlined, CalendarOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../../context/FirebaseContext';

const BoardcardListItem = (props) => {
  const [author, setAuthor] = React.useState('');
  const { getUserByID, isAuthCurrentUser, deletePost } = React.useContext(FirebaseContext);
  const { item, renderUpdate } = props;

  React.useEffect(() => {
    const itemAuthor = async (authorId) => {
      const username = await getUserByID(authorId);
      setAuthor(username);
    };

    itemAuthor(item.author);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const confirmDelete = () => {
    deletePost(item.id);
    renderUpdate();
  };

  return (
    <List.Item key={item.title + '-' + item.created}>
      <List.Item.Meta
        title={
          <>
            <Link to={`/home/singleboard/${item.id}`}>
              {item.title} <EditOutlined />
            </Link>
            {isAuthCurrentUser(item.author) && (
              <Popconfirm
                placement="top"
                title="Confirm to delete this post"
                onConfirm={confirmDelete}
                okText="Yes"
                cancelText="No"
              >
                {' '}
                <Button className="edit-icon" icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </>
        }
      />
      <p className="meta-text">
        <UserOutlined className="green-icon pad-right" />
        {author}
        <br /> <CalendarOutlined className="green-icon pad-right" />
        {new Date(item.created.toDate()).toDateString()}
      </p>
      <p>{item.message}</p>
    </List.Item>
  );
};

export default BoardcardListItem;
