import React from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import { message, notification } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

const firebaseConfig = {
  apiKey: 'AIzaSyCcJCeqKhF6PP3JoKDZPexHNpd1buDznnY',
  authDomain: 'pension-technology-group.firebaseapp.com',
  projectId: 'pension-technology-group',
  storageBucket: 'pension-technology-group.appspot.com',
  messagingSenderId: '474521791416',
  appId: '1:474521791416:web:a703522631815b00023d4d',
  measurementId: 'G-X23RYQSSMM',
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
db.enablePersistence().catch((e) => console.log(e.message));

export const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    let user = firebase.auth().currentUser;

    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const registerUser = (firstname, lastname, email, password) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        db.collection('users').doc(userCredential.user.uid).set({
          firstname,
          lastname,
        });
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const login = (email, password) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        setIsLoggedIn(true);
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const getContacts = async () => {
    const contactsSnap = await db.collection('contacts').get();

    const contacts = [];

    contactsSnap.forEach((doc) => {
      contacts.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return contacts;
  };

  const getBoards = async () => {
    const boardsSnap = await db.collection('boards').orderBy('created', 'desc').get();

    const boards = [];

    boardsSnap.forEach((doc) => {
      boards.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return await boards;
  };

  const getUserByID = async (id) => {
    let userRef = db.collection('users').doc(id);
    const user = await userRef.get();

    if (user.exists) {
      const userData = user.data();
      return await `${userData.firstname} ${userData.lastname}`;
    }

    return '';
  };

  const getBoardByID = async (id) => {
    let boardRef = db.collection('boards').doc(id);
    const boardDoc = await boardRef.get();

    if (boardDoc.exists) {
      return await boardDoc.data();
    }

    return null;
  };

  const getPostComments = async (id) => {
    let commentRef = db.collection('board_relations').doc(id);
    const relatedDoc = await commentRef.get();
    const comments = [];

    if (relatedDoc.exists) {
      const relatedData = relatedDoc.data();

      await relatedData.comments.forEach(async (commentID) => {
        let singleCommentRef = db.collection('comments').doc(commentID);
        const singleCommentDoc = await singleCommentRef.get();

        if (singleCommentDoc.exists) {
          comments.push({ id: singleCommentDoc.id, ...singleCommentDoc.data() });
        }
      });

      return comments;
    }

    return null;
  };

  const deletePost = async (id) => {
    try {
      const boardRelRef = db.collection('board_relations').doc(id);
      const boardRelData = await boardRelRef.get();

      if (boardRelData.exists) {
        const comments = await boardRelData.data().comments;

        comments.forEach(async (comment) => {
          const commentDoc = db.collection('comments').doc(comment.id);
          await commentDoc.delete();
        });

        await boardRelRef.delete();
      }

      const boardDoc = await db.collection('boards').doc(id);
      await boardDoc.delete();
    } catch (e) {
      console.log(e.message);
    }
  };

  const addBoard = async (title, message) => {
    const newBoard = db.collection('boards').doc();
    await newBoard.set({
      author: firebase.auth().currentUser.uid,
      created: firebase.firestore.Timestamp.now(),
      title,
      message,
    });

    const newBordRel = db.collection('board_relations').doc(newBoard.id);
    await newBordRel.set({
      comments: [],
    });
  };

  const addComment = async (id, message) => {
    const newComment = await db.collection('comments').doc();
    newComment.set({
      author: firebase.auth().currentUser.uid,
      created: firebase.firestore.Timestamp.now(),
      message,
    });

    const relationRef = await db.collection('board_relations').doc(id);
    await relationRef.update({ comments: firebase.firestore.FieldValue.arrayUnion(newComment.id) });
  };

  const isAuthCurrentUser = (id) => {
    const uid = firebase.auth().currentUser.uid;

    if (uid === id) {
      return true;
    }

    return false;
  };

  const editBoard = async (id, message) => {
    const boardRef = db.collection('boards').doc(id);
    await boardRef.update({
      message,
    });
  };

  const deleteComment = async (id, key) => {
    const commentDoc = db.collection('comments').doc(id);
    await commentDoc.delete();

    const boardRelData = db.collection('board_relations').doc(key);
    await boardRelData.update({
      comments: firebase.firestore.FieldValue.arrayRemove(id),
    });
  };

  const editComment = async (id, message) => {
    const commentRef = db.collection('comments').doc(id);
    await commentRef.update({
      message,
    });
  };

  const updatePassword = async (value) => {
    try {
      let user = firebase.auth().currentUser;
      await user.updatePassword(value);
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        db,
        isLoggedIn,
        registerUser,
        login,
        getBoards,
        getContacts,
        getUserByID,
        getPostComments,
        getBoardByID,
        addComment,
        addBoard,
        isAuthCurrentUser,
        deletePost,
        editBoard,
        deleteComment,
        editComment,
        updatePassword,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
