export const isInstalled = () => {
  if (
    window.navigator.standalone === true ||
    window.matchMedia('(display-mode: standalone)').matches
  ) {
    console.log('isInstalled: true. Already in standalone mode');
    return true;
  }
  console.log('isInstalled: false.');
  return false;
};
