import React from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined, MailOutlined, SolutionOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../../context/FirebaseContext';

const RegisterForm = (props) => {
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const { registerUser } = React.useContext(FirebaseContext);
  const { handleVisible } = props;

  const handleRegister = () => {
    registerUser(firstName, lastName, email, password);
    handleVisible(false);
  };

  return (
    <Form name="register" className="column light" size="large">
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: 'Please input your Firstname!',
          },
        ]}
      >
        <Input
          onChange={(e) => setFirstName(e.target.value)}
          className="inputField"
          id="firstName"
          placeholder="First name"
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[
          {
            required: true,
            message: 'Please input your Lastname!',
          },
        ]}
      >
        <Input
          onChange={(e) => setLastName(e.target.value)}
          className="inputField"
          id="lastName"
          placeholder="Last name"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input
          prefix={<MailOutlined />}
          onChange={(e) => setEmail(e.target.value)}
          className="inputField"
          id="email"
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >
        <Input
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          className="inputField"
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button
          block={true}
          className="form-btn"
          size="large"
          shape="round"
          icon={<SolutionOutlined />}
          onClick={handleRegister}
        >
          Sign-Up
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;
