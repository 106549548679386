import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'antd';
import { SearchOutlined, FormOutlined, SettingOutlined } from '@ant-design/icons';

const BottomNav = () => {
  return (
    <Menu className="bottom-nav" mode="horizontal">
      <Menu.Item key="search">
        <Link to="/home">
          <Button className="navbar-btn" icon={<SearchOutlined />} />
        </Link>
      </Menu.Item>
      <Menu.Item key="board">
        <Link to="/home/boards">
          <Button className="navbar-btn" icon={<FormOutlined />} />
        </Link>
      </Menu.Item>
      <Menu.Item key="settings">
        <Link to="/home/settings">
          <Button className="navbar-btn" icon={<SettingOutlined />} />
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default BottomNav;
