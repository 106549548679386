import React from 'react';
import { Button, Popconfirm, Input } from 'antd';
import { EditOutlined, DeleteOutlined, SendOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../../context/FirebaseContext';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';

const CommentItem = ({ boardID, comment, renderUpdate }) => {
  const [author, setAuthor] = React.useState('');
  const [editing, setEditing] = React.useState(false);
  const [editMessage, setEditMessage] = React.useState('');
  const { getUserByID, isAuthCurrentUser, deleteComment, editComment } = React.useContext(
    FirebaseContext,
  );

  React.useEffect(() => {
    const getAuthor = async (authorID) => {
      const username = await getUserByID(authorID);
      setAuthor(username);
    };

    getAuthor(comment.author);
    // eslint-disable-next-line
  }, []);

  const confirmDelete = () => {
    deleteComment(comment.id, boardID);
    renderUpdate();
  };

  const handleEditComment = () => {
    editComment(comment.id, editMessage);
    setEditing(false);
    renderUpdate();
  };

  return (
    <div className="comment">
      <p className="meta-text">
        <UserOutlined className="green-icon pad-right" />
        {author}
        <br /> <CalendarOutlined className="green-icon pad-right" />
        {new Date(comment.created.toDate()).toDateString()}
      </p>
      {!editing && comment.message}
      {editing && (
        <>
          <Input
            id="commentInput"
            className="inputField bright marginBottom"
            value={editMessage}
            onChange={(e) => setEditMessage(e.target.value)}
            suffix={<Button shape="circle" icon={<SendOutlined />} onClick={handleEditComment} />}
          />
        </>
      )}
      {isAuthCurrentUser(comment.author) && (
        <>
          <Button
            className="edit-icon"
            onClick={() => {
              setEditing(!editing);
              setEditMessage(comment.message);
            }}
            icon={<EditOutlined />}
          />
          <Popconfirm
            placement="top"
            title="Confirm to delete this comment"
            onConfirm={confirmDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button className="edit-icon" icon={<DeleteOutlined />} />
          </Popconfirm>
        </>
      )}
    </div>
  );
};

export default CommentItem;
