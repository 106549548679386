import React from 'react';
import { List } from 'antd';
import BoardcardListItem from './BoardcardListItem';

const BoardCardList = (props) => {
  const { listData, renderUpdate } = props;

  return (
    <List
      className="card-list"
      itemLayout="vertical"
      size="large"
      pagination={{
        onChange: (page) => {
          console.log(page);
        },
        pageSize: 3,
        simple: true,
      }}
      dataSource={listData}
      renderItem={(item) => <BoardcardListItem renderUpdate={renderUpdate} item={item} />}
    />
  );
};

export default BoardCardList;
