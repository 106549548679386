import React from 'react';
import { Input, Button } from 'antd';
import { PlusOutlined, SendOutlined, CloseOutlined } from '@ant-design/icons';
import { FirebaseContext } from '../context/FirebaseContext';
import BoardCardList from '../components/lists/BoardCardList';

const { Search } = Input;

const Boards = () => {
  const [boardData, setBoardData] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const [newTitle, setNewTitle] = React.useState('');
  const [newMessage, setNewMessage] = React.useState('');
  const { getBoards, addBoard, getUserByID } = React.useContext(FirebaseContext);
  const [update, setUpdate] = React.useState(0);

  React.useEffect(() => {
    const fetchBoardData = async () => {
      const boards = await getBoards();

      if (boards !== false) {
        setBoardData(boards);
      }
    };

    fetchBoardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const handleSubmit = () => {
    addBoard(newTitle, newMessage);
    setNewMessage('');
    setVisible(false);
    renderUpdate();
  };

  const renderUpdate = () => {
    let currentUpdate = update;
    setUpdate(++currentUpdate);
  };

  const handleSearch = async (value) => {
    const boards = await getBoards();
    const filteredBoards = [];

    if (boards !== false) {
      await Promise.all(
        boards.map(async (item) => {
          const authorData = await getUserByID(item.author);
          const concatString = `${item.title} ${item.message} ${authorData}`.toLowerCase();

          if (concatString.includes(value.toLowerCase())) {
            filteredBoards.push(item);
          }
        }),
      );

      setBoardData(filteredBoards);
    }
  };

  return (
    <div className="home-main">
      <Button
        onClick={() => setVisible(!visible)}
        className="plus-btn"
        shape="circle"
        icon={visible ? <CloseOutlined /> : <PlusOutlined />}
      />
      {visible && (
        <div className="board-form">
          <h4>New post</h4>
          <Input
            id="titleInput"
            className="inputField bright marginBottom"
            placeholder="Post title"
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <Input
            id="messageInput"
            className="inputField bright"
            placeholder="Post message"
            onChange={(e) => setNewMessage(e.target.value)}
            suffix={
              <Button
                disabled={newMessage.length < 4}
                shape="circle"
                icon={<SendOutlined />}
                ghost
                onClick={handleSubmit}
              />
            }
          />
        </div>
      )}
      <Search
        size="large"
        placeholder="Search posts"
        allowClear
        onSearch={(value) => handleSearch(value)}
        className="searchField"
      />
      {boardData != null && <BoardCardList renderUpdate={renderUpdate} listData={boardData} />}
    </div>
  );
};

export default Boards;
