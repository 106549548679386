import React from 'react';
import { Form, Input } from 'antd';
import { LockOutlined, CheckCircleOutlined } from '@ant-design/icons';

const INVITE_CODE = '1234';

const CodeForm = (props) => {
  const { onCorrectCode } = props;
  const [isCode, setIsCode] = React.useState(false);

  const handleCode = (code) => {
    if (code.length === 4 && code === INVITE_CODE) {
      setIsCode(true);
      onCorrectCode();
    }
  };

  return (
    <Form name="code" className="column light" size="large">
      <Form.Item
        name="code"
        rules={[
          {
            required: true,
            message: 'Please input your invitation code!',
          },
        ]}
      >
        <Input
          onChange={(e) => handleCode(e.target.value)}
          className="inputField"
          prefix={<LockOutlined />}
          id="code"
          placeholder="Invitation code"
          suffix={isCode && <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} />}
        />
      </Form.Item>
    </Form>
  );
};

export default CodeForm;
